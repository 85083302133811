




import { Component, Vue } from 'vue-property-decorator';

@Component({
	name: 'IndexEvaluation',
})
export default class IndexEvaluation extends Vue {}
